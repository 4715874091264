document.querySelectorAll(".js-contact-form").forEach((element) => {
    element.addEventListener("submit", function (e) {
        e.preventDefault();
        submitContactForm(e);
    });
});
// Contact form send
window.submitContactForm = function submitContactForm(element) {
    let url = element.target.action;
    let btn = element.target.getElementsByTagName("button")[0];
    let form = element.target;
    submitForm(url, btn, form);
};

window.submitTargetContactForm = function submitTargetContactForm(element) {
        let url = element.action;
        let btn = element.getElementsByTagName("button")[0];
        submitForm(url, btn, element);
    };

window.submitForm = function submitForm(url, btn, form) {
        btn.disabled = true;
        btn.classList.add("loading");

        let formData = {};
        let data = new FormData(form);
        for (let [key, value] of data.entries()) {
            formData[key.toString()] = value;
        }
        fetch(url, {
            method: "POST",
            credentials: "same-origin",
            mode: "same-origin",
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": document
                    .getElementsByName("csrf-token")[0]
                    .getAttribute("content"),
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    dataLayer.push({ event: "email_sent" });

                    showToast(data.msg, "success");
                    form.reset();
                } else {
                    let obj = data.msg;
                    let message = "";
                    if (typeof obj === "object") {
                        Object.keys(obj).map(function (key) {
                            message += obj[key] + "\n";
                        });
                    } else {
                        message = obj;
                    }
                    showToast(message, "error");
                }

                btn.disabled = false;
                btn.classList.remove("loading");
            });
};
